import React from 'react';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';
import { rhythm, scale } from '../utils/typography';
import { TopHeading } from '../components/heading';
import { kebabCase } from '../utils/string';

class BlogPostTemplate extends React.Component {
    render() {
        const post = this.props.data.markdownRemark;
        const siteTitle = this.props.data.site.siteMetadata.title;
        const {
            previous,
            next,
            slug,
            translations,
            translatedLinks,
        } = this.props.pageContext;
        const langKey = post.fields.langKey;

        // Replace original links with translated when available.
        let html = post.html;

        function escapeRegExp(str) {
            return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        }

        translatedLinks.forEach(link => {
            let translatedLink = '/' + langKey + link;
            html = html.replace(
                new RegExp('"' + escapeRegExp(link) + '"', 'g'),
                '"' + translatedLink + '"'
            );
        });

        const hasGerman = translations.indexOf('de') !== -1;

        return (
            <Layout
                location={this.props.location}
                title={siteTitle}
                lang={langKey}
                slug={slug}
                hasGerman={hasGerman}
            >
                <SEO
                    title={post.frontmatter.title}
                    lang={langKey}
                    slug={post.fields.slug}
                    description={post.frontmatter.description || post.excerpt}
                />
                <TopHeading smallBottom>{post.frontmatter.title}</TopHeading>
                <small
                    style={{
                        ...scale(-1 / 5),
                        display: `block`,
                        marginBottom: rhythm(0.1),
                    }}
                >
                    {post.frontmatter.date} • {post.timeToRead}{' '}
                    {post.fields.langKey === 'de'
                        ? 'Min. Lesedauer'
                        : 'min read'}
                    <br />
                    Tags:
                    {post.frontmatter.tags &&
                        post.frontmatter.tags.map(tag => (
                            <Link
                                key={tag}
                                to={`/${
                                    langKey !== 'en'
                                        ? `${langKey}/`
                                        : ''
                                }tags/${kebabCase(tag)}/`}
                                style={{
                                    boxShadow: 'none',
                                    color: 'inherit',
                                }}
                            >
                                <span className="postTag">{tag}</span>
                            </Link>
                        ))}
                </small>
                <br />
                <article dangerouslySetInnerHTML={{ __html: html }} />
                <br />
                <hr
                    style={{
                        marginBottom: rhythm(1),
                    }}
                />

                <ul
                    style={{
                        display: `flex`,
                        flexWrap: `wrap`,
                        justifyContent: `space-between`,
                        listStyle: `none`,
                        padding: 0,
                    }}
                >
                    <li>
                        {previous && (
                            <Link to={previous.fields.slug} rel="prev">
                                ← {previous.frontmatter.title}
                            </Link>
                        )}
                    </li>
                    <li>
                        {next && (
                            <Link to={next.fields.slug} rel="next">
                                {next.frontmatter.title} →
                            </Link>
                        )}
                    </li>
                </ul>
            </Layout>
        );
    }
}

export default BlogPostTemplate;

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        site {
            siteMetadata {
                title
                author
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            excerpt(pruneLength: 160)
            timeToRead
            html
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                description
                tags
            }
            fields {
                slug
                langKey
            }
        }
    }
`;
