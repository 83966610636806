/**
 * Transforms string to kebab case.
 *
 * @param {String} str the string to transform
 */
export const kebabCase = str =>
    str
        .replace(/\+/g, 'Plus')
        .match(
            /[A-ZÄÖÜ]{2,}(?=[A-ZÄÖÜ][a-z0-9äöüß]*|\b)|[A-ZAÄÖÜ]?[a-z0-9äöüß]*|[A-ZÄÖÜ]|[0-9]+/g
        )
        .filter(Boolean)
        .map(x => x.toLowerCase())
        .join('-');
